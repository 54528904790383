@import '~@fortawesome/fontawesome-free/css/all.css';
@import '~intl-tel-input/build/css/intlTelInput.css';
#toast-container>div {
  opacity: 1 !important;
}

.table>thead {
  vertical-align: bottom;
  background-color: #716ac8;
  color: #ffff;
}

.table>tbody {
  vertical-align: inherit;
}

.p-sidebar {
  background: #F3F3F9 !important;
}

.group-button {
  float: right;
}


.p-autocomplete-panel .p-autocomplete-items {
    text-align: left !important;
    padding-left: 0px !important;
    margin-bottom: 0px !important;
    padding-bottom: 0 !important;
}


.card-header h3 {
  color: #d8d7e1;
  font-size: 20px;
  margin-bottom: 0;
}

.nav-tabs-custom {
  width: 20%;
}

.invalid-field {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #fd625e;
}

.p-paginator {
  justify-content: left !important;
}

.fixed-width-start {
  width: 5% !important;
}

.fixed-width-end {
  width: 10% !important;
}

.alignIconsAndNumber {
  text-align: center !important;
}

.p-datatable .p-datatable-thead>tr>th {
  text-align: center !important;
  padding: 1rem 1rem;
  border: 1px solid #e9ecef;
  border-width: 0 0 1px 0;
  font-weight: 600;
  color: #495057;
  background: #f3f3f9 !important;
  transition: box-shadow 0.2s;
}

.p-datatable .p-datatable-tbody>tr {
  background: #ffffff;
  color: #495057;
  transition: box-shadow 0.2s;
  cursor: pointer !important;
}

.p-datatable .p-datatable-tbody>tr:hover {
  background: #f0f1ff !important;
}

.copy-row {
  .copy-icon {
    visibility: hidden;
  }
}

.copy-row:hover {
  .copy-icon {
    visibility: visible;
    color: green;
  }
}


.required {
  color: red;
}

.p-datatable .p-sortable-column:focus {
  box-shadow: none !important;
}

.width-0 {
  width: 0 !important;
}

.headline {
  font-size: 19.4px !important;
}

.font-weight-bold {
  font-weight: bold;
}

.anchorClass {
  color: #187BDC;
  text-decoration: underline;
}

.align-right {
  text-align: right;
}

.textcenter{
  text-align: center !important;
}

.p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
  box-shadow: none !important;
}

.p-autocomplete-loader {
  margin-right: 20px !important
}


.textLength {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 31ch;
}


.ml-2 {
  margin-left: 5px !important;
}

.p-paginator-current {
  position: absolute !important;
  right: 0 !important;
}

.btn-soft-primary:hover,
.btn-soft-primary:focus {
  color: #5156be !important;
  background-color: rgba(81, 86, 190, 0.1) !important;
  border-color: transparent !important;
  box-shadow: none !important;
}

.p-calendar {
  width: 100% !important;
}

.p-datepicker table td>span {
  width: 1.5rem !important;
  height: 1.5rem !important;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  text-align: left;
}

.p-placeholder {
  color: #ced4da !important;
  font-size: 0.875rem !important;
}

.toast-container {
  z-index: 99999 !important;
}

.goPage {
  width: 50px;
  margin: 0 10px;
  padding-bottom: 6px;
  vertical-align: middle;
  border: 1px solid #ced4da !important;
  border-radius: 3px !important;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  opacity: 1;
  height: 30px;
  text-indent: calc(100% - 20px)
}


.margin-btm-10 {
  margin-bottom: 10px;
}

.margin-btm-5 {
  margin-bottom: 10px;
}

.cursor-pointer {
  cursor: pointer;
}

.logo_size {
  width: 80%;
}

body.sidebar-open {
  // overflow: hidden;
}

.formio-component-button {
  margin-top: 10px !important;
}

.widthName {
  width: 15%;
}

.formbuilder {
  height: 100% !important;
}

.drag-container.formio-builder-form {
  height: 100% !important;
}

.formio-component-label-hidden {
  height: 100% !important;
}

.formio-form {
  min-height: 100% !important;
  height: 100% !important;
}

.widthfifty {
  width: 50% !important;
}

.widththerty {
  width: 30% !important;
}

.widthfourty {
  width: 40% !important;
}
.widthfourtyfive {
  width: 45% !important;
}

.widtheightfive {
  width: 85% !important;
}

.widthfullpersent {
  width: 100% !important;
}

.widthnintyfive {
  width: 95% !important;
}
.widthninty {
  width: 90% !important;
}

.widthseventy {
  width: 70% !important;
}

.widthseventyfive {
  width: 75% !important;
}

.widthsixty {
  width: 60% !important;
}

.widthsixtyfive {
  width: 65% !important;
}

.widththertyfive {
  width: 35% !important;
}
.widthtwetyfive {
  width: 25% !important;
}
.textalignleft {
  text-align: left !important;
}

.emtwetyfive{
  width: 25rem !important
}

.vmtwetyfive{ width: 25vw }

.rotateninty{
  transform: rotate(98deg) !important
}

.p-datatable .p-datatable-tbody>tr>td {
  border: 1px solid #e9ecef !important;
}

.p-datatable .p-datatable-thead>tr>th {
  border: 1px solid #e9ecef !important;
}

.p-dropdown-items {
  padding-left: 0px !important;
  padding-bottom: 0px !important;
  margin-bottom: 0px !important;
}

.p-multiselect-items {
  padding-left: 0px !important;
  padding-bottom: 0px !important;
  margin-bottom: 0px !important;
}
